import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'

import TextContainer from 'components/common/text-container'
import Navigation from 'components/navigation'
import Layout from 'components/layout'
import PortfolioImage from 'components/portfolio/image'
import { leastTransparentGray } from 'components/theme/colours'
import { Heading, SubHeading } from 'components/common/typography'

const Grid = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 100%;
  margin-top: 16px;

  @media (max-width: 1500px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1501px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const Spacer = styled.div`
  height: 60px;
`

const FullScreenImageContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 0;
  width: 100%;
  height: 100%;
  background-color: ${leastTransparentGray};
  z-index: 1;

  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
    max-height: 100vh;

    img {
      object-fit: contain !important;
    }
  }
`

export default class PortfolioCategoryTemplate extends React.Component {
  state = { fullScreenImageIndex: null }

  handleImageClick = index => {
    this.setState({ fullScreenImageIndex: index })
  }

  render() {
    const {
      pageContext: { category },
      data,
    } = this.props
    const { fullScreenImageIndex } = this.state
    const portfolioCategory = data.portfolioCategory

    return (
      <Layout>
        <Helmet
          title={`${portfolioCategory.label} | Pinya Letters`}
          meta={[
            {
              name: 'description',
              content:
                'Pinya Letters carries an extensive portfolio of work demonstrating unique craft in wedding calligraphy, in-store art, and live-events.',
            },
          ]}
        >
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  name: 'Portfolio',
                  item: 'https://pinyaletters.com/portfolio',
                },
                {
                  '@type': 'ListItem',
                  position: 2,
                  name: portfolioCategory.label,
                  item: `https://pinyaletters.com/portfolio/${portfolioCategory.slug}`,
                },
              ],
            })}
          </script>
        </Helmet>
        <Navigation solidNav />
        <Spacer />
        <TextContainer>
          <Heading>{portfolioCategory.label}</Heading>
          <div
            dangerouslySetInnerHTML={{
              __html: portfolioCategory.description.content[0].content[0].value,
            }}
          />
        </TextContainer>
        {fullScreenImageIndex !== null && fullScreenImageIndex >= 0 && (
          <FullScreenImageContainer onClick={() => this.setState({ fullScreenImageIndex: null })}>
            <Img fluid={portfolioCategory.images[fullScreenImageIndex].fullViewImageFluid}/>
          </FullScreenImageContainer>
        )}
        <TextContainer>
          <Grid>
            {portfolioCategory.images.map((asset, index) => (
              <div key={asset.id} onClick={() => this.handleImageClick(index)}>
                <PortfolioImage asset={asset} />
              </div>
            ))}
          </Grid>
        </TextContainer>
        <TextContainer>
          <Heading as='h2'>Get a quote</Heading>
          Get a <Link to='/contact'>quote or contact me</Link> for any questions you may have about
          your wedding or other event!
        </TextContainer>
        <TextContainer>
          <SubHeading as='h3'>See my other work</SubHeading>
          Take a look at the <Link to='/portfolio'>rest of my portfolio</Link> for inspiration for
          your next event!
        </TextContainer>
      </Layout>
    )
  }
}

export const query = graphql`
  query PortfolioCategoryTemplateQuery($category: String!) {
    portfolioCategory: contentfulPortfolioCategory(slug: { eq: $category }) {
      id
      slug
      label
      description {
        content {
          content {
            value
          }
        }
      }

      images {
        id

        fullViewImageFluid: fluid(maxWidth: 1600, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }

        ...PortfolioImageContentfulAsset
      }
    }
  }
`
